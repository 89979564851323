import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ContactForm from 'components/contact-form'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'

interface Props {
  location: Location
}

const IntroductionPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid
  const patient_img_left = data.patient_img_left?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <link rel="canonical" href="https://meridianendoindy.com/patient-information/introduction/" />
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Endodontics Patient Information Indianapolis IN</title>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Endodontics Patient Information Indianapolis IN"/>
        <meta property="description" content="Patient Information from Indianapolis IN Endodontist Dr. Broady. 317-846-4980"/>
        <meta property="og:description" content="Patient Information from Indianapolis IN Endodontist Dr. Broady. 317-846-4980"/>
        <meta property="og:url" content="https://meridianendoindy.com/patient-information/introduction/"/>
        <meta property="og:image" content={OGImage}/>
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
        <meta name="twitter:url" content="https://meridianendoindy.com/patient-information/introduction/" />
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="Endodontics Patient Information Indianapolis IN"/>
        <meta name="twitter:description" content="Patient Information from Indianapolis IN Endodontist Dr. Broady. 317-846-4980"/>
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="introduction-hero-section-bg hero-bg"
        fluid={hero_background}
        alt="hero background image"
      >
        <div id="introduction-hero-section">
          <Container>
            <div className="col-lg-5 left-content pb-5">
              <h1 className="primary-text hero-heading">
                Patient Information
              </h1>
              <p className="hero-text">
                Thank you for your interest in Meridian Endodontics, a dental practice dedicated exclusively to endodontic care.
              </p>
              <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
            </div>
            <div className="col-lg-7">
              <Img fluid={hero_img} className="hero-img" alt="lady wearing sunglasses"/>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id="faded-bg"></section>
      <section id="introduction-section-1">
        <Container>
          <div className="col-lg-12">
            <h4> Patient Information </h4>
            <p>
              Thank you for your interest in Meridian Endodontics, a top-referred endodontist in Indianapolis. Dr. Broady and Dr. Patterson are specialists in their field of practice and respected members of the American Association of Endodontics. Our dental team is lead by Dr. Broady, a specialist in endodontic care.
            </p>
            <p>Meridian Endodontics provides the highest standard of professional care using the latest technology and treatment techniques. To schedule an appointment with Dr. Broady or Dr. Patterson, contact our office at <a href="tel:3178464980">317-846-4980.</a></p>
          </div>
          <div className="col-lg-12 introduction-container">
            <div className="row">
              <div className="col-lg-4">
                <Img fluid={patient_img_left} className="patient-img-left" />
              </div>
              <div className="col-lg-8">
                <h5>Continue to the next page to learn more about:</h5>
                <div className="row align-items-start ">
                  <div className="col-lg-4">
                    <ul className="mb-0">
                      <Link to="/patient-information/first-visit" className="introduction-links"><li>First Visit</li></Link>
                      <Link to="/patient-information/scheduling" className="introduction-links"><li>Scheduling</li></Link>
                      <Link to="/patient-information/financial-policy" className="introduction-links"><li>Financial Policy</li></Link>
                    </ul>
                  </div>
                  <div className="col-lg-8">
                    <ul>
                      <Link to="/patient-information/insurance" className="introduction-links"><li>Insurance</li></Link>
                      <Link to="/patient-information/endodontic-faq" className="introduction-links"><li>FAQ</li></Link>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section id="introduction-section-3">
        <ContactForm/>
      </section>
    </Layout>
  )
}

export default IntroductionPage

export const query = graphql`
  query IntroductionPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "introduction-hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    patient_img_left: file(name: { eq: "patient-img-left" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
